import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import NavMenu from "./NavMenu";
import { Rocket, SpeechBubbleComponent } from "./RocketComponents";
import About from "./About";
import Contact from "./Contact";
import ProjectsSection from "./ProjectsSection";
import logoImage from "./logo.png";
import backgroundImage1 from "./backgroundimg.png";
import "./styles/global.css";
import "./App.css";

const HeroSection = ({
  messages,
  currentMessageIndex,
  handleGetStartedClick,
  launchRocket,
  showButton,
}) => {
  return (
    <Element name="hero-section">
      <div className="hero-container">
        <Rocket launch={launchRocket} left="15%" top="40%" />
        {messages.map((message, index) => (
          <SpeechBubbleComponent
            key={index}
            visible={currentMessageIndex === index && !launchRocket}
            message={message}
          />
        ))}
        <div className="content-wrapper">
          <img src={logoImage} alt="LogoImage" className="LogoImage" />
          <button
            onClick={handleGetStartedClick}
            className={`bg-[#ff4500] text-white px-4 py-2 rounded-md hover:bg-[#e63f00] transition-colors duration-200 ${
              showButton ? "visible" : "hidden"
            }`}
          >
            Get Started!
          </button>
        </div>
      </div>
    </Element>
  );
};

const App = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [launchRocket, setLaunchRocket] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const messages = [
    "Hello visitor, welcome to my portfolio!",
    "May I ask you to do a small favor for me?",
    "Could you click the 'Get Started' button please?",
    "It will launch us into the cosmos!",
  ];

  useEffect(() => {
    const messageInterval = setInterval(() => {
      if (currentMessageIndex < messages.length - 1) {
        setCurrentMessageIndex(currentMessageIndex + 1);
      } else {
        clearInterval(messageInterval);
      }
    }, 6000);

    return () => clearInterval(messageInterval);
  }, [currentMessageIndex, messages.length]);

  const handleGetStartedClick = () => {
    setLaunchRocket(true);
    setShowButton(false);
    setTimeout(() => {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 2500);
  };

  return (
    <div
      className="app-wrapper"
      style={{ backgroundImage: `url(${backgroundImage1})` }}
    >
      <NavMenu />
      <HeroSection
        messages={messages}
        currentMessageIndex={currentMessageIndex}
        handleGetStartedClick={handleGetStartedClick}
        launchRocket={launchRocket}
        showButton={showButton}
      />
      <Element name="about-section" id="about-section">
        <About />
      </Element>
      <Element name="ProjectsSection">
        <ProjectsSection />
      </Element>
      <Element name="contact-section">
        <Contact />
      </Element>
    </div>
  );
};

export default App;
