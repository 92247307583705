import React, { useState } from "react";
import { FaReact, FaCode, FaPalette, FaTrophy } from "react-icons/fa";
import { MdWeb, MdMusicNote } from "react-icons/md";
import { IoMdRocket } from "react-icons/io";

// Separate content file
const content = {
  sectionTitle: "Projects Overview",
  expandButtonText: "Expand",
  collapseButtonText: "Collapse",
  detailSections: {
    status: { title: "Status", icon: IoMdRocket },
    overview: { title: "Overview", icon: MdWeb },
    builtWith: { title: "Built With", icon: FaPalette },
    challenges: { title: "Challenges", icon: FaTrophy },
  },
};

const projectsData = [
  {
    title: "Portfolio Website",
    description: [
      "This website you are currently viewing",
      "What being used for this portfolio like API's",
      "The challenges, Progress so far and ideas for the future",
    ],
    status: "In progress",
    overview: [
      "Responsive design:",
      "Since every mobile device can have different resolutions we need to create custom media queries in order to make it look good on at least the most (common) resolutions",
      "Interactive project cards:",
      "It only shows the expanded project this way I can show more information in the fields to maintain focus",
      "Contactform:",
      "I integrated Google API with my contactform, this way I can have full control over my data",
      "The data gets transformed from JSON into a Google sheet",
      "Some features are",
      "Form validation: Ensures that the Name, Email, and Message fields are not empty. If they are missing, an error is shown 'Missing required fields,",
      "Minimal amount of characters:The name for instance need to consist a minimal of 2 characters",
    ],
    builtWith: ["React", "Tailwind CSS"],
    challenges:
      "Creating a design that effectively showcases diverse projects and skills",
    icon: FaReact,
  },
  {
    title: "Artist Booking Website",
    description: [
      "Website of an artist with integrated booking system.",
      "Intuitive UI, so everyone can navigate through the website",
      "Make it look visual appealing as well as functional",
    ],
    status: "In Progress",
    overview: [
      "A customer visits the website and has the ability to book the artist Nancy Beerens. If the customer is not already familiar with the artist, they can find samples to get an impression. Besides the samples, the genres and songs are displayed within (Mijn aanbod). This is not the voice of the artist herself but the songs she could sing at the event. There would be a starting price displayed, but the exact price can only be determined by sending a new booking request. This leads directly to the Contact Form.",
    ],
    builtWith: ["React"],
    challenges:
      "Finding the sweet spot between intuitive interaction and technical complexity",
    icon: MdMusicNote,
  },
  // ... (other projects)
];

const ProjectCard = ({
  title,
  description,
  status,
  overview,
  builtWith,
  challenges,
  icon: Icon,
  expanded,
  onToggle,
  isVisible,
}) => {
  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => {
        const highlightedPhrases = [
          "Responsive design:",
          "Interactive project cards:",
          "Contactform:",
          "Some features are",
        ];
        const phrase = highlightedPhrases.find((phrase) =>
          item.startsWith(phrase)
        );
        if (phrase) {
          const [firstPart, ...rest] = item.split(":");
          return (
            <p key={index} className="mb-2">
              <span className="font-bold bg-gradient-to-r from-white to-[#ff4500] text-transparent bg-clip-text">
                {firstPart}:
              </span>
              {rest.join(":")}
            </p>
          );
        }
        return (
          <p key={index} className="mb-2">
            {item}
          </p>
        );
      });
    }
    return <p>{content}</p>;
  };

  return (
    <div
      className={`bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-lg overflow-hidden transition-all duration-500 ${
        isVisible ? "opacity-100 scale-100" : "opacity-0 scale-80"
      } ${expanded ? "md:col-span-2" : ""}`}
    >
      <div className="p-6">
        <div className="flex items-center mb-4">
          <div className="text-4xl mr-4 text-[#ff4500]">{Icon && <Icon />}</div>
          <h3 className="text-xl font-bold text-white">{title}</h3>
        </div>
        <div className="text-gray-300 mb-4">{renderContent(description)}</div>
        <button
          onClick={onToggle}
          className="bg-[#ff4500] text-white px-4 py-2 rounded-md hover:bg-[#e63f00] transition-colors duration-200"
        >
          {expanded ? content.collapseButtonText : content.expandButtonText}
        </button>
        <div
          className={`mt-4 overflow-hidden transition-all duration-500 ${
            expanded ? "max-h-[2000px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          {Object.entries(content.detailSections).map(
            ([key, { title, icon }]) => (
              <DetailSection
                key={key}
                title={title}
                icon={icon}
                content={renderContent(eval(key))}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

const DetailSection = ({ title, icon: Icon, content }) => (
  <div className="mt-4 p-4 bg-gray-800 rounded-lg">
    <h4 className="text-lg font-semibold text-[#ff4500] flex items-center mb-2">
      <Icon className="mr-2" />
      {title}
    </h4>
    <div className="text-gray-300">{content}</div>
  </div>
);

const ProjectsSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section id="projects-section" className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          {content.sectionTitle}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projectsData.map((project, index) => (
            <ProjectCard
              key={index}
              {...project}
              expanded={expandedIndex === index}
              onToggle={() => toggleExpand(index)}
              isVisible={expandedIndex === null || expandedIndex === index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
