import React, { useState, useEffect, useRef } from "react";
import backgroundImage1 from "./backgroundimg.png";
import astronaut from "./astronaut.png";
import "./Contact.css";

const GOOGLE_SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbyz4jDNeRSkC18VwWCLMIMu5E99icZXzeADoYGgQAtfSg6AT5i2nMS3wHX8ioqJe7naxw/exec";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Message: "",
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.location.hash === "#contact-section" && contactRef.current) {
        const navbar = document.querySelector("nav");
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        const yOffset = -navbarHeight;
        const y =
          contactRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    handleScroll(); // Call once on mount
    window.addEventListener("hashchange", handleScroll);
    return () => window.removeEventListener("hashchange", handleScroll);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.Name.trim().length < 2) {
      setResponseMessage("Name must be at least 2 characters long.");
      return false;
    }
    if (!emailRegex.test(formData.Email)) {
      setResponseMessage("Please enter a valid email address.");
      return false;
    }
    if (formData.Message.trim().length < 10) {
      setResponseMessage("Message must be at least 10 characters long.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSubmitStatus("error");
      return;
    }
    setSubmitStatus("submitting");
    setResponseMessage("");

    const form = new FormData();
    form.append("Name", formData.Name.trim());
    form.append("Email", formData.Email.trim());
    form.append("Message", formData.Message.trim());

    try {
      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: "POST",
        mode: "cors",
        body: form,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.result === "success") {
        setSubmitStatus("success");
        setResponseMessage(result.message);
        setFormData({ Name: "", Email: "", Message: "" });
      } else {
        throw new Error(result.message || "Form submission failed");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      setSubmitStatus("error");
      setResponseMessage(
        "There was an error submitting the form. Please try again."
      );
    }
  };

  return (
    <section
      id="contact-section"
      ref={contactRef}
      className="min-h-screen flex justify-center items-center py-20 bg-cover bg-center relative"
      style={{ backgroundImage: `url(${backgroundImage1})` }}
    >
      <div className="container mx-auto px-4">
        <div className="bg-gradient-to-br from-gray-900 to-gray-800 bg-opacity-90 rounded-3xl shadow-2xl p-8 md:p-12 max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img
                src={astronaut}
                alt="Astronaut"
                className="w-full max-w-md mx-auto"
              />
            </div>
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold text-white mb-6">
                Let's connect!
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <input
                    className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#ff4500]"
                    placeholder="Name"
                    name="Name"
                    value={formData.Name}
                    onChange={handleChange}
                    required
                    minLength="2"
                  />
                </div>
                <div>
                  <input
                    className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#ff4500]"
                    placeholder="Email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    required
                    type="email"
                  />
                </div>
                <div>
                  <textarea
                    className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#ff4500]"
                    placeholder="Message"
                    name="Message"
                    value={formData.Message}
                    onChange={handleChange}
                    required
                    minLength="10"
                    rows="4"
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    className="bg-gray-600 text-white px-6 py-2 rounded-md hover:bg-gray-500 transition-colors duration-200"
                    type="button"
                    onClick={() =>
                      setFormData({ Name: "", Email: "", Message: "" })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#ff4500] text-white px-6 py-2 rounded-md hover:bg-[#e63f00] transition-colors duration-200"
                    type="submit"
                    disabled={submitStatus === "submitting"}
                  >
                    {submitStatus === "submitting" ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
              {submitStatus && (
                <div
                  className={`mt-4 p-3 rounded-md ${
                    submitStatus === "success" ? "bg-green-600" : "bg-red-600"
                  } text-white`}
                >
                  {responseMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
