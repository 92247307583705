import React from "react";
import { scroller } from "react-scroll";
import { FaHome, FaIdCard, FaLaptopCode } from "react-icons/fa";
import { GrContact } from "react-icons/gr";

const NavMenu = () => {
  const menuItems = [
    { to: "hero-section", label: "Home", icon: <FaHome /> },
    { to: "about-section", label: "About", icon: <FaIdCard /> },
    { to: "projects-section", label: "Projects", icon: <FaLaptopCode /> },
    { to: "contact-section", label: "Contact", icon: <GrContact /> },
  ];

  const handleClick = (to) => {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
      offset: -80,
    });
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-br from-gray-900/80 to-gray-800/80 backdrop-blur-sm shadow-lg">
      <div className="container mx-auto px-4">
        <ul className="flex justify-center items-center py-3 space-x-8">
          {menuItems.map((item) => (
            <li key={item.label} className="text-center">
              <button
                onClick={() => handleClick(item.to)}
                className="flex flex-col items-center focus:outline-none focus:ring-2 focus:ring-[#ff4500] focus:ring-opacity-50 transition-colors duration-200"
                aria-label={`Go to ${item.label} section`}
              >
                <span className="text-2xl text-[#ff4500] mb-1">
                  {item.icon}
                </span>
                <span className="text-xs text-white">{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavMenu;
