import React from "react";
import backgroundImage1 from "./backgroundimg.png";
import profilePicture from "./profile.png";

const ProfileImage = ({ src, alt, size = "md" }) => {
  const sizeClasses = {
    sm: "w-24 h-24",
    md: "w-32 h-32",
    lg: "w-66 h-64", // Adjusted to be square
    xl: "w-64 h-64",
  };

  return (
    <img
      src={src}
      alt={alt}
      className={`rounded-full object-cover ${
        sizeClasses[size] || sizeClasses.md
      }`}
    />
  );
};

const About = () => {
  return (
    <section
      id="about-section"
      className="min-h-screen flex justify-center items-center py-20"
      style={{
        backgroundImage: `url(${backgroundImage1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mx-auto px-4">
        <div className="bg-gradient-to-br from-gray-900/80 to-gray-800/80 backdrop-blur-md shadow-lg p-8 md:p-12 max-w-4xl mx-auto">
          <div className="space-y-8">
            <div className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-8">
              <ProfileImage src={profilePicture} alt="Profile" size="lg" />
              <div>
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
                  How it started ..
                </h2>
                <p className="text-gray-300 leading-relaxed">
                  Welcome to my portfolio! My name is Rick Beerens, and my
                  interest in development began when I discovered AI or
                  particularly ChatGPT and the incredible potential it offers. I
                  started with small projects, like creating platform games
                  using the Pygame library.
                </p>
              </div>
            </div>

            <div>
              <p className="text-gray-300 leading-relaxed">
                As I progressed I realized that while AI is impressive, without
                solid programming skills it can be difficult to overcome the
                challenges you encounter along the way. That's when I decided to
                follow the path of self-study and eventually master full-stack
                development.
              </p>
            </div>

            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
                The journey unfolds
              </h2>
              <p className="text-gray-300 leading-relaxed">
                I had some basic experience with Linux from earlier projects,
                but my knowledge was limited. I started learning how to set up
                my own web server and gain the skills needed for hosting. I’ve
                hosted Docker containers, deployed GitHub projects on my own
                domain, and consistently explored new projects to run on my VPS.
              </p>
            </div>

            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
                The road ahead
              </h2>
              <p className="text-gray-300 leading-relaxed">
                At a certain point, I asked myself, "Why not try to find a job
                within this field if I'm really so hyped about it?" So I
                searched for some job offers, but unfortunately, I lack the
                working experience. I made this portfolio to show my knowledge
                so far in web development. I think my background as a system
                administrator brings a unique skill set because I understand the
                infrastructure behind the code. I started my self-study, and
                let's see what we can do. I'm sure that when you truly like
                something, you can get really good at it as well.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
