import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import rocketImage from "./Rocket.png";

const hoverAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const launchAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  100% { transform: translate(70vw, -70vh) scale(0.3); opacity: 0; }
`;

const RocketContainer = styled.div`
  height: 15vh;
  width: 15vh;
  position: absolute;
  left: ${(props) => props.left || "10%"};
  top: ${(props) => props.top || "35%"};
  animation: ${(props) =>
    props.isLaunching
      ? css`
          ${launchAnimation} 5s ease-in-out forwards
        `
      : css`
          ${hoverAnimation} 3s ease-in-out infinite
        `};
  z-index: 2;

  @media (max-width: 768px) {
    height: 10vh;
    width: 10vh;
  }
`;

const RocketImage = styled.img`
  height: 100%;
  width: 100%;
`;

const SpeechBubbleContainer = styled.div`
  position: absolute;
  left: ${(props) => props.left || "calc(10% + 8vh)"};
  top: ${(props) => props.top || "calc(35% - 10vh)"};
  z-index: 2;
  height: 8vh;
  display: flex;
  align-items: flex-left;

  @media (max-width: 768px) {
    left: ${(props) => props.left || "calc(10% + 5vh)"};
    top: ${(props) => props.top || "calc(35% - 7vh)"};
    height: 6vh;
  }
`;

const SpeechBubble = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  color: #000;
  font-size: 14px;
  padding: 10px;
  width: 25vw;
  max-width: 250px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : "20px")});
  transition: opacity 0.5s ease, transform 0.5s ease;
  position: absolute;
  bottom: 0;

  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 20px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #fff transparent transparent;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px;
    width: 40vw;
  }
`;

export const Rocket = ({ launch, left, top }) => {
  const [isLaunching, setIsLaunching] = useState(false);

  useEffect(() => {
    if (launch) {
      setIsLaunching(true);
    }
  }, [launch]);

  return (
    <RocketContainer isLaunching={isLaunching} left={left} top={top}>
      <RocketImage src={rocketImage} alt="Rocket" />
    </RocketContainer>
  );
};

export const SpeechBubbleComponent = ({ visible, message, left, top }) => {
  return (
    <SpeechBubbleContainer left={left} top={top}>
      <SpeechBubble visible={visible}>{message}</SpeechBubble>
    </SpeechBubbleContainer>
  );
};
